body {
  margin: 0;
  padding: 0;
  font-family: sans-serif; }

body div.login-background {
  background-position: bottom; }

.create-page .array-input__simple-form-iterator ul > :first-child > li,
.edit-page .array-input__simple-form-iterator ul > :first-child > li {
  border-bottom: none; }
  .create-page .array-input__simple-form-iterator ul > :first-child > li > p,
  .edit-page .array-input__simple-form-iterator ul > :first-child > li > p {
    display: none; }

@media only screen and (min-width: 1280px) {
  .create-page .ra-input.ra-input__flex-25 > .array-input__simple-form-iterator,
  .edit-page .ra-input.ra-input__flex-25 > .array-input__simple-form-iterator {
    width: unset; }
    .create-page .ra-input.ra-input__flex-25 > .array-input__simple-form-iterator ul > :first-child > li,
    .edit-page .ra-input.ra-input__flex-25 > .array-input__simple-form-iterator ul > :first-child > li {
      flex-wrap: wrap; }
    .create-page .ra-input.ra-input__flex-25 > .array-input__simple-form-iterator.fullwidth,
    .edit-page .ra-input.ra-input__flex-25 > .array-input__simple-form-iterator.fullwidth {
      width: 100%; } }

.create-page .array-input__simple-form-iterator--no-label .ra-input > :first-child,
.edit-page .array-input__simple-form-iterator--no-label .ra-input > :first-child {
  margin-top: 0; }

.create-page .array-input__simple-form-iterator--inline ul > :first-child > li,
.edit-page .array-input__simple-form-iterator--inline ul > :first-child > li {
  border-bottom: none; }
  .create-page .array-input__simple-form-iterator--inline ul > :first-child > li > p,
  .edit-page .array-input__simple-form-iterator--inline ul > :first-child > li > p {
    display: none; }

.create-page .array-input__simple-form-iterator--inline ul > :first-child,
.edit-page .array-input__simple-form-iterator--inline ul > :first-child {
  display: flex;
  flex-wrap: wrap; }
  .create-page .array-input__simple-form-iterator--inline ul > :first-child > li,
  .edit-page .array-input__simple-form-iterator--inline ul > :first-child > li {
    margin-right: 1em; }

@media only screen and (min-width: 1280px) {
  form.simple-form.form-inline > :first-child,
  form.tabbed-form .tab-inline {
    display: flex;
    flex-wrap: wrap; }
    form.simple-form.form-inline > :first-child .ra-field,
    form.simple-form.form-inline > :first-child .ra-input,
    form.tabbed-form .tab-inline .ra-field,
    form.tabbed-form .tab-inline .ra-input {
      order: 1;
      width: 100%;
      box-sizing: border-box;
      padding-right: 0.5em; }
      form.simple-form.form-inline > :first-child .ra-field.ra-field__flex-25, form.simple-form.form-inline > :first-child .ra-field.ra-input__flex-25,
      form.simple-form.form-inline > :first-child .ra-input.ra-field__flex-25,
      form.simple-form.form-inline > :first-child .ra-input.ra-input__flex-25,
      form.tabbed-form .tab-inline .ra-field.ra-field__flex-25,
      form.tabbed-form .tab-inline .ra-field.ra-input__flex-25,
      form.tabbed-form .tab-inline .ra-input.ra-field__flex-25,
      form.tabbed-form .tab-inline .ra-input.ra-input__flex-25 {
        width: 25%; }
      form.simple-form.form-inline > :first-child .ra-field.ra-field__flex-50, form.simple-form.form-inline > :first-child .ra-field.ra-input__flex-50,
      form.simple-form.form-inline > :first-child .ra-input.ra-field__flex-50,
      form.simple-form.form-inline > :first-child .ra-input.ra-input__flex-50,
      form.tabbed-form .tab-inline .ra-field.ra-field__flex-50,
      form.tabbed-form .tab-inline .ra-field.ra-input__flex-50,
      form.tabbed-form .tab-inline .ra-input.ra-field__flex-50,
      form.tabbed-form .tab-inline .ra-input.ra-input__flex-50 {
        width: 50%; }
      form.simple-form.form-inline > :first-child .ra-field.ra-field__flex-75, form.simple-form.form-inline > :first-child .ra-field.ra-input__flex-75,
      form.simple-form.form-inline > :first-child .ra-input.ra-field__flex-75,
      form.simple-form.form-inline > :first-child .ra-input.ra-input__flex-75,
      form.tabbed-form .tab-inline .ra-field.ra-field__flex-75,
      form.tabbed-form .tab-inline .ra-field.ra-input__flex-75,
      form.tabbed-form .tab-inline .ra-input.ra-field__flex-75,
      form.tabbed-form .tab-inline .ra-input.ra-input__flex-75 {
        width: 75%; }
      form.simple-form.form-inline > :first-child .ra-field.ra-field__noflex, form.simple-form.form-inline > :first-child .ra-field.ra-input__noflex, form.simple-form.form-inline > :first-child .ra-field.ra-field__flex-100, form.simple-form.form-inline > :first-child .ra-field.ra-input__flex-100,
      form.simple-form.form-inline > :first-child .ra-input.ra-field__noflex,
      form.simple-form.form-inline > :first-child .ra-input.ra-input__noflex,
      form.simple-form.form-inline > :first-child .ra-input.ra-field__flex-100,
      form.simple-form.form-inline > :first-child .ra-input.ra-input__flex-100,
      form.tabbed-form .tab-inline .ra-field.ra-field__noflex,
      form.tabbed-form .tab-inline .ra-field.ra-input__noflex,
      form.tabbed-form .tab-inline .ra-field.ra-field__flex-100,
      form.tabbed-form .tab-inline .ra-field.ra-input__flex-100,
      form.tabbed-form .tab-inline .ra-input.ra-field__noflex,
      form.tabbed-form .tab-inline .ra-input.ra-input__noflex,
      form.tabbed-form .tab-inline .ra-input.ra-field__flex-100,
      form.tabbed-form .tab-inline .ra-input.ra-input__flex-100 {
        width: 100%; }
      form.simple-form.form-inline > :first-child .ra-field.ra-input__v-align, form.simple-form.form-inline > :first-child .ra-field.ra-field__v-align,
      form.simple-form.form-inline > :first-child .ra-input.ra-input__v-align,
      form.simple-form.form-inline > :first-child .ra-input.ra-field__v-align,
      form.tabbed-form .tab-inline .ra-field.ra-input__v-align,
      form.tabbed-form .tab-inline .ra-field.ra-field__v-align,
      form.tabbed-form .tab-inline .ra-input.ra-input__v-align,
      form.tabbed-form .tab-inline .ra-input.ra-field__v-align {
        align-self: center; }
        form.simple-form.form-inline > :first-child .ra-field.ra-input__v-align > :first-child, form.simple-form.form-inline > :first-child .ra-field.ra-field__v-align > :first-child,
        form.simple-form.form-inline > :first-child .ra-input.ra-input__v-align > :first-child,
        form.simple-form.form-inline > :first-child .ra-input.ra-field__v-align > :first-child,
        form.tabbed-form .tab-inline .ra-field.ra-input__v-align > :first-child,
        form.tabbed-form .tab-inline .ra-field.ra-field__v-align > :first-child,
        form.tabbed-form .tab-inline .ra-input.ra-input__v-align > :first-child,
        form.tabbed-form .tab-inline .ra-input.ra-field__v-align > :first-child {
          margin-bottom: 8px;
          margin-top: 16px; } }

@media only screen and (min-width: 960px) {
  .edit-page.candidate-order form.simple-form > div:first-child {
    min-height: 700px;
    align-content: flex-start; }
  .edit-page.candidate-order form.simple-form .ra-input-object_state_id {
    width: unset;
    position: absolute;
    right: 5em; }
  .edit-page.candidate-order form.simple-form .ra-input-internal_reporting_note ul div li,
  .edit-page.candidate-order form.simple-form .ra-input-reporting_note ul div li {
    padding: 8px; }
  .edit-page.candidate-order form.simple-form .ra-input-internal_reporting_note .theme-dark ul div li:nth-child(even),
  .edit-page.candidate-order form.simple-form .ra-input-reporting_note .theme-dark ul div li:nth-child(even) {
    background-color: #303030; }
  .edit-page.candidate-order form.simple-form .ra-input-internal_reporting_note .theme-light ul div li:nth-child(even),
  .edit-page.candidate-order form.simple-form .ra-input-reporting_note .theme-light ul div li:nth-child(even) {
    background-color: #efefef; } }

@media only screen and (min-width: 960px) {
  .edit-page.candidate form.simple-form .ra-input-mobility_note > :first-child,
  .edit-page.candidate form.tabbed-form .ra-input-mobility_note > :first-child,
  .create-page.candidate form.simple-form .ra-input-mobility_note > :first-child,
  .create-page.candidate form.tabbed-form .ra-input-mobility_note > :first-child {
    width: 256px; } }
